import React, { useState } from "react";
import { Form, Formik } from "formik";

// CC
import FileInput from "../CCW-FileInputNew";
import ButtonLoader from "../CCW-ButtonLoader";

// Helpers
import isError from "Helpers/TypeHelpers/IsError";

// Styles
import { ButtonContainer, Footer } from "./styles";

// Types
import type UploadImageFormProps from "./types";

// Form Data
import { Validation, InitialValues } from "./FormLogic";
import CCButton from "../CCW-CCButton";

export const UploadImageForm: React.FC<UploadImageFormProps> = ({
	className,
	onCancel,
	onSubmit,
	resetOnClose = true,
	formID,
}) => {
	// State
	const [activeFormID, setActiveFormID] = useState<string | undefined>();

	return (
		<div className={className} id="form-upload-csv">
			<Formik
				initialValues={InitialValues}
				validationSchema={Validation}
				validateOnChange
				onSubmit={async (
					{ image },
					{ setSubmitting, resetForm, setFieldError },
				) => {
					if (!image) return null;
					setSubmitting(true);

					try {
						// Upload image file
						await onSubmit(image);
						resetForm();
					} catch (err) {
						setFieldError(
							"image",
							isError(err)
								? err.message
								: "Action failed. Please contact support",
						);
					} finally {
						setSubmitting(false);
					}
				}}>
				{({ isSubmitting, values, errors, initialValues, resetForm }) => {
					// If the form has values in it from a different FormID, reset the form
					if (formID !== activeFormID && resetOnClose) {
						values !== initialValues && resetForm();
						setActiveFormID(formID);
					}

					return (
						<Form>
							<FileInput name="image" accept="image/png" multiple={false} />
							<Footer>
								<ButtonContainer>
									<ButtonLoader
										loading={isSubmitting}
										type="submit"
										disabled={!values.image?.length || !!errors.image}>
										Upload
									</ButtonLoader>
									<CCButton variant="washed" onClick={onCancel}>
										Cancel
									</CCButton>
								</ButtonContainer>
							</Footer>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default UploadImageForm;
