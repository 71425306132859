import styled from "@emotion/styled";

// Types
export const Footer = styled.div(() => ({
	display: "flex",
	justifyContent: "space-between",
	gap: "1.5rem",
	marginTop: "4.4rem",
}));

export const ButtonContainer = styled.div(() => ({
	display: "flex",
	gap: "1.5rem",
}));
