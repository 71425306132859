// Helpers
import putReq from "Helpers/NetworkingHelpers/PutReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import { JSONApi, DataHelperResponse } from "Types/JSONApi";

type APIKeys = { createdAt: string; lastUsed: string; partner: string };

type Meta = { token: string };

type Response = JSONApi<APIKeys, undefined, undefined, Meta, undefined>;

/**
 * @async
 * Replaces the partner API Keys and returns them
 * @param partnerID Partner ID of the keys you wish to replace
 * @returns a DataHelperResponse tuple of APIKeys, Related, and the full response
 * @throws Networking error
 */
const replacePartnerAPIKeys = async (
	partnerID: string,
	keyType: "live" | "test",
): Promise<DataHelperResponse<APIKeys, undefined, Response>> => {
	const response = await putReq<Response>(
		`${
			import.meta.env.VITE_BASE_URL
		}executive/partners/${partnerID}/api-key/${keyType}`,
		undefined,
		false,
		1,
	);

	return transformAPIResponse(response, {});
};

export default replacePartnerAPIKeys;
