import React from "react";

// Types
import IconProps from "Types/IconProps";

export const ClipboardIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "clipboard-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "20px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 47 46"
			fill={fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.375 10.5682C3.375 7.99791 5.62471 5.99303 8.18579 5.7756C10.3986 5.58774 12.8889 5.06109 14.5227 3.75465C14.9354 3.42464 15.3482 3.05624 15.7666 2.68274C17.2344 1.37279 18.7725 0 20.6258 0H26.3753C28.2283 2.46712e-06 29.7655 1.37226 31.2326 2.68198C31.6513 3.05574 32.0643 3.42442 32.4773 3.75465C34.1111 5.06109 36.6015 5.58774 38.8142 5.7756C41.3753 5.99303 43.625 7.99791 43.625 10.5682V41.6875C43.625 44.0692 41.6942 46 39.3125 46H7.6875C5.30577 46 3.375 44.0692 3.375 41.6875V10.5682ZM26.375 8.625C26.375 10.2128 25.0878 11.5 23.5 11.5C21.9122 11.5 20.625 10.2128 20.625 8.625C20.625 7.03718 21.9122 5.75 23.5 5.75C25.0878 5.75 26.375 7.03718 26.375 8.625ZM19.1452 35.3015C19.4318 35.2437 19.5386 34.8901 19.3319 34.6833L16.1304 31.4812C15.9237 31.2745 15.57 31.3812 15.5122 31.6678L14.7028 35.6781C14.6509 35.9349 14.8775 36.1615 15.1343 36.1098L19.1452 35.3015ZM16.9131 30.2496C16.77 30.1066 16.77 29.8746 16.9131 29.7316L27.707 18.9371C28.2793 18.3647 29.2072 18.3647 29.7795 18.937L31.8755 21.033C32.4478 21.6053 32.4478 22.5331 31.8755 23.1054L21.0809 33.8999C20.9378 34.043 20.7058 34.043 20.5627 33.8999L16.9131 30.2496Z"
			/>
		</svg>
	);
};

export default ClipboardIcon;
