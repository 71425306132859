import useSWR from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";
import { transformAPIResponse } from "Helpers/JsonApiHelpers";

// Types
import type { JSONApi, DataHookResponse } from "Types/JSONApi";

type APIKeys = { createdAt: string; lastUsed: string; partner: string };

type Response = JSONApi<APIKeys, undefined, undefined, undefined, undefined>;

/**
 * Get the API keys for a certain partner id
 * @param partnerID the partner id to get the keys for
 * @returns a DataHookResponse tuple of api key data, Error, Mutate, Related, and the full response
 */
const usePartnerAPIKey = (
	partnerID: string | undefined,
	keyType: "live" | "test",
): DataHookResponse<APIKeys, undefined, Response> => {
	const { data, error, mutate } = useSWR<Response, Error>(
		partnerID
			? [
					`${
						import.meta.env.VITE_BASE_URL
					}executive/partners/${partnerID}/api-key/${keyType}`,
					1,
			  ]
			: null,
		getReq,
		{
			shouldRetryOnError: false,
		},
	);

	const [apiKey, related, fullJSONApiResponse] = transformAPIResponse(data, {});

	return [apiKey, error, mutate, related, fullJSONApiResponse];
};

export default usePartnerAPIKey;
