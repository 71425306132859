import React, { ReactNode, useState } from "react";

// CC
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import PageContainer from "CCW-Components/CCW-Container";
import PageHeader from "CCW-Components/CCW-PageHeader";
import ToggleInput from "CCW-Components/CCW-ToggleInput";
import ButtonLoader from "CCW-Components/CCW-ButtonLoader";
import LineTable from "CCW-Components/CCW-LineTable";

// Hooks
import usePartnerAPIKey from "Hooks/UsePartnerAPIKey";
import useSession from "Hooks/UseSession";
import useAlert from "Hooks/UseAlert";
import useBreakpoints from "Hooks/UseBreakpoints";

// Helpers
import { breakpoints } from "Helpers/WebUIHelpers/Breakpoints";
import replacePartnerAPIKeys from "Helpers/NetworkingHelpers/ReplacePartnerAPIKey";

// Assets
import KeyIcon from "Assets/SVG/KeyIcon";
import { CircleArrowIcon, ClipboardIcon, PlusIcon } from "Assets/SVG";

// Types
import type { PartnerAccount } from "Types/Account";

// Styles
import {
	Container,
	ToggleExplainer,
	ToggleBox,
	MainGrid,
	TitleBox,
	Title,
	Hint,
	HeaderRow,
	DataRow,
	Copyable,
	Table,
	TextBox,
	FlexRow,
	Border,
	Rotate,
	LearnMore,
	Flex,
	Padding,
	Position,
	Bold,
} from "./styles";

const DefaultDashboardPartnerPage: React.FC = (props) => {
	const [user, , , { activeAccount }] = useSession<PartnerAccount>();

	const [keyType, setKeyType] = useState<"live" | "test">("live");

	const [generatedKey, setGeneratedKey] = useState<string | undefined>();

	const [isResettingKey, setIsResettingKey] = useState(false);

	const [, reportAlert] = useAlert();

	const [APIKeyData, , APIKeyMutate] = usePartnerAPIKey(
		activeAccount?._id,
		keyType,
	);

	const width = useBreakpoints();
	const isDesktop = breakpoints[1] <= width;

	// Helpers
	const resetAPIKey = async () => {
		try {
			setIsResettingKey(true);
			if (activeAccount?._id) {
				const [, , response] = await replacePartnerAPIKeys(
					activeAccount?._id,
					keyType,
				);
				if (response?.meta?.token) {
					setGeneratedKey(response?.meta?.token);
					reportAlert(
						"🎉 API key sucessfully created. You can now copy it from the table",
						"success",
					);
					APIKeyMutate({ ...response, meta: undefined });
				} else {
					throw "Nope";
				}
			} else {
				throw "Nope";
			}
		} catch {
			reportAlert(
				"Key generation failed. Please try again or contact support if the issue continues.",
				"error",
			);
		} finally {
			setIsResettingKey(false);
		}
	};

	// Loading state
	if (!user) return <BlockLoader message="Loading user data..." />;

	const tableData: ReactNode[][] = [
		[
			<Bold key="1">Name</Bold>,
			APIKeyData ? `API Key For Partner: ${activeAccount?._id}` : "",
		],
		[
			<Bold key="1">Token</Bold>,
			APIKeyData ? (
				<Copyable
					key="1a"
					isClickable={!!generatedKey}
					onClick={async () => {
						if (generatedKey) {
							await navigator.clipboard.writeText(generatedKey);
							reportAlert("API Key copied to clipboard", "success");
						}
					}}>
					{generatedKey ? (
						<>
							<span>{generatedKey}</span>
							<ClipboardIcon height="2.2rem" width="2.2rem" />
						</>
					) : (
						"hidden"
					)}
				</Copyable>
			) : (
				""
			),
		],
		[
			<Bold key="3">Last Used</Bold>,
			APIKeyData ? new Date(APIKeyData?.lastUsed).toLocaleDateString() : "",
		],
		[
			<Bold key="3">Created</Bold>,
			APIKeyData ? new Date(APIKeyData?.createdAt).toLocaleDateString() : "",
		],
	];

	return (
		<DefaultPage {...props}>
			<PageContainer>
				<PageHeader>API Keys</PageHeader>
				<Container>
					<Flex>
						<ToggleExplainer>Viewing {keyType} API Keys</ToggleExplainer>
						<ToggleBox>
							<ToggleInput
								id={"test-keys"}
								label="View test keys"
								onChange={(isTestKey) => {
									setKeyType(isTestKey ? "test" : "live");
									setGeneratedKey(undefined);
								}}
								checked={keyType === "test"}
								styleType={"slick"}
							/>
						</ToggleBox>
					</Flex>
					<MainGrid>
						<TitleBox>
							<TextBox>
								<FlexRow>
									<KeyIcon width={"2.8rem"} height={"1.4rem"} />
									<Title>Secret Key</Title>
								</FlexRow>
								<Hint>
									This key will allow you to authenticate API requests.{" "}
									<a
										href="https://api-docs.climateclever.org/docs/cc-api/nq67itw2yfsig-partner-api"
										target="_blank"
										rel="noreferrer"
										style={{ color: "inherit" }}>
										Learn more
									</a>
								</Hint>
							</TextBox>
						</TitleBox>
						<Border>
							{isDesktop ? (
								<Table>
									<thead>
										<HeaderRow>
											{tableData.map(([name], index) => (
												<th key={index + "name"}>{name}</th>
											))}
										</HeaderRow>
									</thead>
									<tbody>
										<DataRow>
											{tableData.map(([name, content]) => (
												<td key={name + "content"}>{content}</td>
											))}
										</DataRow>
									</tbody>
								</Table>
							) : (
								<Padding>
									<LineTable rows={tableData} noRulers />
								</Padding>
							)}
						</Border>
						<Position>
							<ButtonLoader
								border={true}
								colorVariant="grape"
								onClick={() => resetAPIKey()}
								loading={isResettingKey}>
								<FlexRow>
									<PlusIcon width="1.8rem" height="" /> Create secret key
								</FlexRow>
							</ButtonLoader>
						</Position>
						<LearnMore
							href="https://api-docs.climateclever.org/docs/cc-api/nq67itw2yfsig-partner-api"
							target="_blank"
							rel="noreferrer">
							Learn more about API authentication{" "}
							<Rotate>
								<CircleArrowIcon width="2.4rem" height="" />
							</Rotate>
						</LearnMore>
					</MainGrid>
				</Container>
			</PageContainer>
		</DefaultPage>
	);
};

export default DefaultDashboardPartnerPage;
