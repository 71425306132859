import { Counts } from "CCW-Components/CCW-Sharehub/types";
import type { Partner } from "Types/Partner";

export const getUsers = (partner: Partner): Counts => ({
	school: {
		basic: partner.schoolSubs?.basic.uses ?? 0,
		standard: partner.schoolSubs?.standard.uses ?? 0,
		pro: partner.schoolSubs?.pro.uses ?? 0,
	},
	business: {
		basic: partner.businessSubs?.basic.uses ?? 0,
		standard: partner.businessSubs?.standard.uses ?? 0,
		pro: partner.businessSubs?.pro.uses ?? 0,
	},
});

export const getUsedSubs = (partner: Partner): Counts => ({
	school: {
		basic: partner.schoolSubs?.basic.used ?? 0,
		standard: partner.schoolSubs?.standard.used ?? 0,
		pro: partner.schoolSubs?.pro.used ?? 0,
	},
	business: {
		basic: partner.businessSubs?.basic.used ?? 0,
		standard: partner.businessSubs?.standard.used ?? 0,
		pro: partner.businessSubs?.pro.used ?? 0,
	},
});

export const getRemainingSubs = (partner: Partner): Counts => ({
	school: {
		basic: partner.schoolSubs?.basic
			? partner.schoolSubs.basic.uses - partner.schoolSubs.basic.used
			: 0,
		standard: partner.schoolSubs?.standard
			? partner.schoolSubs.standard.uses - partner.schoolSubs.standard.used
			: 0,
		pro: partner.schoolSubs?.pro
			? partner.schoolSubs.pro.uses - partner.schoolSubs.pro.used
			: 0,
	},
	business: {
		basic: partner.businessSubs?.basic
			? partner.businessSubs.basic.uses - partner.businessSubs.basic.used
			: 0,
		standard: partner.businessSubs?.standard
			? partner.businessSubs.standard.uses - partner.businessSubs.standard.used
			: 0,
		pro: partner.businessSubs?.pro
			? partner.businessSubs.pro.uses - partner.businessSubs.pro.used
			: 0,
	},
});
