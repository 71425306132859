import Platform from "Types/Platform";
import { Tier } from "Types/Tiers";

const homesFree = [
	"3 emission sources available",
	"Access to actions",
	"Purchase offsets",
];

const homesBasic = [
	"All emission sources available",
	"Access to actions",
	"Purchase offsets",
	"In app support (live messaging)",
	"Upload external offsets",
];

const free = [
	"1 site included",
	"1 emission source available",
	"Access to actions",
	"Purchase offsets",
	"CSV data uploads",
];

const basic = [
	"2 sites included",
	"5 emission sources available",
	"Access to actions",
	"Purchase offsets",
	"CSV data uploads",
	"In app support (live messaging)",
];

const standard = [
	"10 sites included",
	"All emission sources available",
	"Upload external offsets",
	"Capture work from home data",
	"Carbon footprint report",
	"30 mins private consultation/support",
];

const pro = [
	"15 sites included",
	"All emission sources available",
	"Create historic carbon footprint reports",
	"12 hrs private consultation/support",
];

export const getFeatures = (
	platform: Platform,
): { [name in Tier]?: string[] } => ({
	free,
	basic,
	standard,
	pro,
	...(platform === "home" && {
		free: homesFree,
		basic: homesBasic,
	}),
});
