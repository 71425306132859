import React from "react";

// Types
import IconProps from "Types/IconProps";

export const EditIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "edit-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4722_8332EDITICON)">
				<path
					d="M1.5 1.5H7.75C8.16421 1.5 8.5 1.83579 8.5 2.25C8.5 2.66421 8.16421 3 7.75 3H2C1.72386 3 1.5 3.22386 1.5 3.5V14.5C1.5 14.7761 1.72386 15 2 15H13C13.2761 15 13.5 14.7761 13.5 14.5V8.75C13.5 8.33579 13.8358 8 14.25 8C14.6642 8 15 8.33579 15 8.75V15C15 15.8284 14.3284 16.5 13.5 16.5H1.5C0.671573 16.5 0 15.8284 0 15V3C0 2.17157 0.671573 1.5 1.5 1.5Z"
					fill={fill || "currentColor"}
				/>
				<path
					d="M7.3808 11.2278C7.51689 11.364 7.44656 11.5968 7.25788 11.6348L4.61712 12.167C4.44806 12.2011 4.29891 12.0519 4.33303 11.8828L4.86592 9.24244C4.904 9.05378 5.13685 8.98351 5.27294 9.11962L7.3808 11.2278ZM5.78828 7.96768C5.69409 8.06187 5.69409 8.21458 5.78827 8.30878L8.19115 10.7121C8.28534 10.8063 8.43808 10.8063 8.53228 10.7121L15.6393 3.60512C16.0161 3.22834 16.0161 2.61745 15.6393 2.24066L14.2593 0.860714C13.8825 0.483921 13.2716 0.48393 12.8948 0.860735L5.78828 7.96768Z"
					fill={fill || "currentColor"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_4722_8332EDITICON">
					<rect
						width="16"
						height="16"
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default EditIcon;
