import React from "react";

// Types
import IconProps from "Types/IconProps";

const BoltIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "bolt-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "16px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fill={fill || "#fbb12c"}
				d="M5.06666 16.6668L7.26666 10.1668H0.666666L10.9333 -0.666504L8.73333 5.8335H15.3333L5.06666 16.6668Z"
			/>
		</svg>
	);
};

export default BoltIcon;
