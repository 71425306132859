import React from "react";

// Types
import type IconProps from "Types/IconProps";

const CompletedIcon: React.FC<IconProps> = ({
	width,
	height,
	id,
	className = "completed-icon",
	fill,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "64px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 64 65"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M32 65C49.6731 65 64 50.6731 64 33C64 15.3269 49.6731 1 32 1C14.3269 1 0 15.3269 0 33C0 50.6731 14.3269 65 32 65ZM20 32.4832L22.052 30.5848C24.452 31.7464 25.976 32.6296 28.676 34.5544C33.7472 28.7968 37.1024 25.876 43.3304 22L44 23.536C38.8616 28.0216 35.0984 33.0136 29.6792 42.7336C26.336 38.7976 24.1064 36.2872 20 32.4832Z"
				fill={fill != null ? fill : "#5E9842"}
			/>
		</svg>
	);
};

export default CompletedIcon;
