import styled from "@emotion/styled";
import mediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Container = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "1.2rem",
	marginTop: "2.4rem",
	[mediaQueries[2]]: {
		gap: "6rem",
		flexDirection: "row",
		alignItems: "start",
	},
	"& svg": {
		flexShrink: 0,
	},
	"& a": {
		textDecoration: "none",
		...theme.webFonts.italic,
	},
}));

export const Flex = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",
	flexShrink: 0,
	gap: "1rem",
	[mediaQueries[2]]: {
		flexDirection: "column",
		gap: "2rem",
	},
}));

export const ToggleExplainer = styled.div(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.grey500,
}));

export const ToggleBox = styled.div(({ theme }) => ({
	padding: "1.4rem 2.3rem",
	border: `0.1rem solid ${theme.colors.grey200}`,
	borderRadius: "1rem",
	...theme.webFonts.F6,
	color: theme.colors.grey500,
	backgroundColor: theme.colors.White,
}));

export const MainGrid = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "auto",
	gridTemplateRows: "auto auto",
	borderRadius: "1rem 1rem 0 0",
	overflow: "hidden",
	flexGrow: 1,
}));

export const Border = styled.div(({ theme }) => ({
	borderRadius: "0 0 1rem 1rem",
	overflow: "hidden",
	border: `0.1rem solid ${theme.colors.grey200}`,
}));

export const Table = styled.table(({ theme }) => ({
	display: "table",
	borderSpacing: 0,
	textAlign: "left",
	borderCollapse: "collapse",
	width: "100%",
	backgroundColor: theme.colors.White,
}));

export const TitleBox = styled.div(({ theme }) => ({
	background: theme.colors.secondary,
	color: theme.colors.grey50,
	padding: "1.4rem 2rem",
	borderRadius: "1rem 1rem 0 0",
	overflow: "hidden",
	[mediaQueries[1]]: {
		padding: "2.5rem 2.5rem 2rem",
	},
}));

export const TextBox = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.6rem",
}));

export const FlexRow = styled.div(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	gap: "1.2rem",
}));

export const Title = styled.span(({ theme }) => ({
	...theme.webFonts.F5,
	...theme.webFonts.bold,
}));

export const Hint = styled.span(({ theme }) => ({
	...theme.webFonts.F7,
	color: theme.colors.grey50,
}));

export const HeaderRow = styled.tr(({ theme }) => ({
	...theme.webFonts.F7,
	...theme.webFonts.bold,
	color: theme.colors.grey600,
	background: theme.colors.grey100,
	borderBottom: `0.1rem solid ${theme.colors.grey200}`,
	"> *": {
		padding: "1rem 1rem",
		":first-child": {
			paddingLeft: "2.4rem",
		},
		":last-child": {
			paddingRight: "2.4rem",
		},
	},
}));

export const DataRow = styled.tr(({ theme }) => ({
	...theme.webFonts.F7,
	color: theme.colors.grey500,
	overflow: "hidden",
	"> *": {
		padding: "2.4rem 1rem",
		":first-child": {
			paddingLeft: "2.4rem",
		},
		":last-child": {
			paddingRight: "2.4rem",
		},
	},
}));

export const Bold = styled.span(({ theme }) => ({
	...theme.webFonts.bold,
}));

export const Copyable = styled.span<{ isClickable: boolean }>(
	({ theme, isClickable }) => ({
		...(isClickable
			? {
					color: theme.colors.secondary,
					cursor: "pointer",
					wordBreak: "break-all",
					display: "flex",
					gap: "1rem",
					alignItems: "center",
			  }
			: theme.webFonts.italic),
	}),
);

export const Rotate = styled.div(({ theme }) => ({
	transform: "rotate(-0.25turn)",
}));

export const LearnMore = styled.a(({ theme }) => ({
	...theme.webFonts.F7,
	...theme.webFonts.italic,
	color: theme.colors.secondary,
	display: "flex",
	gap: "1rem",
	alignItems: "center",
	justifyContent: "end",
	cursor: "pointer",
	marginTop: "1.4rem",
	"& svg path": {
		fill: theme.colors.secondary,
	},
	[mediaQueries[1]]: {
		marginBottom: "1.4rem",
		gridRowStart: 1,
	},
}));

export const Padding = styled.div({
	padding: "0.7rem 1.5rem 1rem",
});

export const Position = styled.div({
	justifySelf: "stretch",
	marginTop: "1.6rem",
	[mediaQueries[1]]: {
		justifySelf: "end",
	},
	"& > *": {
		width: "100%",
	},
});
