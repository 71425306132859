import styled from "@emotion/styled";
import GradientText from "CCW-Components/CCW-GradientText";

// Helpers
import MediaQueries, { mediaQueries } from "Helpers/WebUIHelpers/Breakpoints";

// Types
export const OuterContainer = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	boxShadow: theme.shadow,
	borderRadius: "1.2rem",
	overflow: "hidden",
}));

export const Heading = styled.div(({ theme }) => ({
	...theme.webFonts.h4,
	padding: "1.8rem",
	color: theme.colors.grey50,
	background: theme.colors.primary,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "1.6rem",
	"> svg": {
		height: "2.6rem",
		width: "2.6rem",
		fill: theme.colors.grey50,
		"& > path": {
			fill: theme.colors.grey50,
		},
	},
	[MediaQueries[0]]: {
		"> svg": {
			height: "2.4rem !important",
			width: "2.4rem !important",
		},
	},
	[MediaQueries[2]]: {
		"> svg": {
			height: "2.8rem !important",
			width: "2.8rem !important",
		},
	},
}));

export const ContentContainer = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
}));

export const HorizontalStack = styled.div<{ allowVerticalForMobile?: boolean }>(
	({ allowVerticalForMobile = false, theme }) => ({
		backgroundColor: theme.colors.White,
		display: "flex",
		flexDirection: allowVerticalForMobile ? "column" : "row", // Only for mobile
		justifyContent: "center",
		alignItems: "center",
		gap: `${allowVerticalForMobile ? 1 : 2}rem`,
		padding: "1rem",
		[mediaQueries[3]]: {
			flexDirection: "row",
			padding: "1rem 5rem",
		},
	}),
);

export const VerticalStack = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: "0.8rem",
	padding: "1rem",
	flexBasis: "8rem",
	[mediaQueries[2]]: {
		padding: "2rem",
		flexBasis: "12rem",
	},
}));

export const PlatformTitle = styled(GradientText)(({ theme }) => ({
	...theme.webFonts.h5,
	margin: "0",
	textTransform: "capitalize",
}));

export const Divider = styled.div(({ theme }) => ({
	height: "1px",
	backgroundColor: theme.colors.Black05,
}));

export const TierName = styled.h5(({ theme }) => ({
	...theme.webFonts.h5,
	display: "block",
	textTransform: "uppercase",
	margin: "1rem 0 0.8rem",
	color: theme.colors.Blue400,
}));

export const Amount = styled.span(({ theme }) => ({
	...theme.webFonts.F4,
	margin: "0",
}));
