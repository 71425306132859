import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// CC
import PageHeader from "CCW-Components/CCW-PageHeader";
import SharehubInfoContainer from "CCW-Components/CCW-SharehubInfoContainer";
import Container from "CCW-Components/CCW-Container";
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import ModalBanner from "CCW-Components/CCW-ModalBanner";
import UploadImageForm from "CCW-Components/CCW-UploadImageForm";
import CCButton from "CCW-Components/CCW-CCButton";

// Hooks
import { useTheme } from "@emotion/react";

// Assets
import {
	BoltIcon,
	ClipboardIcon,
	OnboardingIcon,
	BusinessIcon,
	LinkIcon,
	SchoolIcon,
	HomeIcon,
} from "Assets/SVG";

// Styles
import {
	Grid,
	Stats,
	LinksTable,
	TitleContainer,
	List,
	Item,
	InfoLink,
	Logo,
	IconBox,
	LinkTitle,
	CopyButton,
	FlexColumn,
	Note,
	Bold,
	LinksBox,
	Capitalise,
	LogoBox,
	LogoEditContainer,
	EditIcon,
} from "./styles";

// Types
import type IconProps from "Types/IconProps";
import Platform from "Types/Platform";
import { SharehubProps } from "./types";

const Sharehub: React.FC<SharehubProps> = ({
	counts,
	name,
	images,
	referralCode,
	platformsToIncludeInLinks,
	websitesToIncludeInLinks,
	onBannerChange,
	onLogoChange,
}) => {
	// Hooks
	const [lastCopiedLink, setLastCopiedLink] = useState<string>();
	const [imageType, setImageType] = useState<"banner" | "logo" | undefined>();
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const platforms: {
		[platform in Platform]?: {
			icon: React.FC<IconProps>;
		};
	} = {
		home: { icon: HomeIcon },
		business: { icon: BusinessIcon },
		school: { icon: SchoolIcon },
	};

	const EditButton = (
		<EditIcon width="1.6rem" height="1.6rem" fill={theme.colors.Grape400} />
	);

	return (
		<DefaultPage>
			<Container>
				<PageHeader>{name}</PageHeader>
				<Grid>
					<LogoBox>
						<LogoEditContainer
							onClick={() => {
								setImageType("logo");
								navigate({
									...location,
									hash: "upload-logo",
								});
							}}>
							{images.logoSRC ? (
								<>
									<Logo src={images.logoSRC} />
									{EditButton}
								</>
							) : (
								<CCButton>Add a Logo</CCButton>
							)}
						</LogoEditContainer>
						<LogoEditContainer
							onClick={() => {
								setImageType("banner");
								navigate({
									...location,
									hash: "upload-banner",
								});
							}}>
							{images.bannerSRC ? (
								<>
									<Logo src={images.bannerSRC} />
									{EditButton}
								</>
							) : (
								<CCButton>Add a Banner</CCButton>
							)}
						</LogoEditContainer>
					</LogoBox>
					<ModalBanner
						id={`upload-${imageType}`}
						matchType="hash"
						returnUrl={{
							...location,
							pathname: "./",
							hash: "",
						}}
						headline={`Upload ${imageType} image`}
						isSimpleDesign={true}>
						<UploadImageForm
							formID={imageType}
							onSubmit={async (image) => {
								imageType === "banner"
									? await onBannerChange(image)
									: await onLogoChange(image);
								navigate({
									...location,
									hash: "",
								});
							}}
							onCancel={() => {
								navigate({
									...location,
									pathname: "./",
									hash: "",
								});
							}}
						/>
					</ModalBanner>
					<LinksBox>
						<LinksTable>
							<TitleContainer>
								<LinkIcon width="20" height="20" />
								<span>Grab your shareable links</span>
							</TitleContainer>
							<List>
								{platformsToIncludeInLinks.map((platform) => {
									const Icon = platforms[platform]?.icon as React.FC<IconProps>;
									const queryParams = new URLSearchParams({
										"referral-code": referralCode,
										"account-type": platform,
									});
									return (
										<Item key={platform}>
											<IconBox>
												<Icon width="22px" height="22px" />
											</IconBox>
											<FlexColumn>
												{websitesToIncludeInLinks.map((websiteType, index) => {
													const url =
														websiteType === "sign up"
															? `https://app.climateclever.org/sign-up/1?${queryParams.toString()}`
															: `https://www.climateclever.org?${queryParams.toString()}`;
													return (
														<InfoLink key={platform + index}>
															<div>
																<LinkTitle>
																	<Capitalise>
																		{platform} {websiteType} link
																	</Capitalise>
																</LinkTitle>
															</div>
															{lastCopiedLink === url ? (
																<span>Copied!</span>
															) : (
																<CopyButton
																	onClick={() => {
																		setLastCopiedLink(url);
																		navigator.clipboard.writeText(
																			url as string,
																		);
																	}}>
																	Copy
																</CopyButton>
															)}
														</InfoLink>
													);
												})}
											</FlexColumn>
										</Item>
									);
								})}
							</List>
						</LinksTable>
						<Note>
							<Bold>Note:</Bold> Landing page links give users an overview of
							the platform before sign up. If you are building your own landing
							page you can use the direct sign up links to bypass this.
						</Note>
					</LinksBox>
					<Stats>
						{!!counts.signups && (
							<SharehubInfoContainer
								title="Number of sign ups"
								signUpsPerPlatform={counts.signups}
								icon={ClipboardIcon}
							/>
						)}
						{!!counts.subsidiesUsed && (
							<SharehubInfoContainer
								title="Subsidies given out"
								signUpsPerPlatform={counts.subsidiesUsed}
								icon={OnboardingIcon}
							/>
						)}
						{!!counts.subsidiesLeft && (
							<SharehubInfoContainer
								title="Subsidies left"
								signUpsPerPlatform={counts.subsidiesLeft}
								icon={BoltIcon}
							/>
						)}
					</Stats>
				</Grid>
			</Container>
		</DefaultPage>
	);
};

export default Sharehub;
