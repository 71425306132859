// Assets
import SchoolIcon from "Assets/SVG/SchoolIcon";
import HomeIcon from "Assets/SVG/HomeIcon";
import BusinessIcon from "Assets/SVG/BusinessIcon";

// Types
import Platform from "Types/Platform";

const selectPlatformIcon = (platform: Platform) => {
	switch (platform) {
		case "school":
			return SchoolIcon;
		case "home":
			return HomeIcon;
		case "business":
			return BusinessIcon;
		default:
			throw new Error("Unknown platform");
	}
};

export default selectPlatformIcon;
