import styled from "@emotion/styled";
import { EditIcon as CCEditIcon } from "Assets/SVG";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Stats = styled.div(() => ({
	gridArea: "stats",
	display: "flex",
	flexDirection: "column",
	gap: "1.2rem",
	[MediaQueries[1]]: {
		gap: "2.4rem",
	},
}));

export const Grid = styled.div(({ theme }) => ({
	display: "grid",
	alignItems: "start",
	justifyItems: "stretch",
	gap: "1.6rem",
	gridTemplateColumns: "1fr",
	gridTemplateRows: "auto",
	gridTemplateAreas: `"logo"
        "stats"
        "links"`,
	marginTop: "2rem",
	[MediaQueries[1]]: {
		gap: "2rem 4rem",
		gridTemplateColumns: "minmax(auto, 36rem) 1fr",
		gridTemplateRows: "max-content 1fr",
		gridTemplateAreas: `"logo stats"
        "links stats"`,
		marginTop: "5rem",
	},
}));

export const LogoBox = styled.div({
	gridArea: "logo",
	maxWidth: "34rem",
	placeSelf: "start",
	display: "flex",
	gap: "0.6rem",
});

export const LogoEditContainer = styled.div({
	position: "relative",
});

export const EditIcon = styled(CCEditIcon)({
	position: "absolute",
	top: "0.6rem",
	right: "0.6rem",
	cursor: "pointer",
	padding: "0.2rem",
	backgroundColor: "white",
	borderRadius: "0.4rem",
});

export const Logo = styled.img(({ theme }) => ({
	borderRadius: "1rem",
	border: `0.1rem solid ${theme.colors.grey200}`,
	maxWidth: "100%",
}));

export const LinksBox = styled.div(({ theme }) => ({
	gridArea: "links",
	[MediaQueries[2]]: {
		maxWidth: "34rem",
	},
}));

export const LinksTable = styled.div(({ theme }) => ({
	border: `1px solid ${theme.colors.grey200}`,
	borderRadius: "1.2rem",
	overflow: "hidden",
}));

export const TitleContainer = styled.div(({ theme }) => ({
	...theme.webFonts.F6,
	...theme.webFonts.bold,
	color: theme.colors.White,
	background: theme.colors.secondary,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	padding: "1rem",
	gap: "1rem",
}));

export const List = styled.dl(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	margin: "0",
	backgroundColor: theme.colors.White,
	"& > div:not(:last-of-type)": {
		borderBottom: `1px solid ${theme.colors.D5}`,
	},
}));

export const Item = styled.div(() => ({
	display: "flex",
	alignItems: "start",
	gap: "1.8rem",
	padding: "1.4rem 1.8rem 1.4rem 2rem",
}));

export const Link = styled.dt(({ theme }) => ({
	...theme.webFonts.F6,
	color: theme.colors.D3,
	margin: "0",
	border: "none",
	whiteSpace: "nowrap",
}));

export const InfoLink = styled.div(({ theme }) => ({
	...theme.webFonts.F7,
	color: theme.colors.grey500,
	display: "flex",
	flexDirection: "row",
	gap: "1.8rem",
	justifyContent: "space-between",
}));

export const LinkTitle = styled.span(({ theme }) => ({
	...theme.webFonts.F7,
	...theme.webFonts.bold,
	color: theme.colors.grey600,
}));

export const CopyButton = styled.button(({ theme }) => ({
	...theme.webFonts.F7Bold,
	color: theme.colors.secondary,
	border: "none",
	background: "none",
	"&:hover": {
		...theme.webFonts.F7,
	},
	"&:active": {
		transform: "translateY(2px) scale(0.98)",
	},
}));

export const IconBox = styled.div(({ theme }) => ({
	flexShrink: 0,
	color: theme.colors.secondary,
}));

export const FlexColumn = styled.div({
	flexGrow: "1",
	display: "flex",
	flexDirection: "column",
	gap: "1.2rem",
	overflow: "hidden",
});

export const Note = styled.div(({ theme }) => ({
	marginTop: "1.6rem",
	...theme.webFonts.F7,
	...theme.webFonts.italic,
	color: theme.colors.grey500,
}));

export const Bold = styled.span(({ theme }) => ({
	...theme.webFonts.bold,
	color: theme.colors.grey600,
}));

export const Capitalise = styled.span(() => ({
	textTransform: "capitalize",
}));
