import React from "react";

// CC
import AppError from "CCW-Components/CCW-AppError";

// Styles
import { Title, Grid, CellWrapper, Text } from "./styles";

// Types
import type LineTableProps from "./types";

export const LineTable: React.FC<LineTableProps> = ({
	title,
	rows,
	noDataMessage,
	noRulerOnBottom = false,
	noRulers = false,
	noMinWidthOnCells = false,
}) => {
	const columnCount: number | undefined = rows.reduce(
		(count: undefined | number, row) => {
			if (count === undefined) {
				return row.length;
			} else if (count === row.length) {
				return count;
			} else {
				// returning zero signifies an error due to cell count mismatch in the rows
				return 0;
			}
		},
		undefined,
	);

	// Error state
	if (!columnCount && rows.length) {
		return <AppError error={new Error("Table data malformed")} />;
	}

	if (rows[0]?.length === 2) {
		// If it's a two-column table, mutate rows so that all elements on the
		// right of the table line up with the table edge (align right)
		rows = rows.map(([firstElement, secondElement], index) => [
			firstElement,
			<div key={index} style={{ textAlign: "right", flexGrow: 1 }}>
				{secondElement}
			</div>,
		]);
	}

	// Normal state
	return rows.length || noDataMessage ? (
		<>
			{title && <Title>{title}</Title>}
			{!!rows.length && (
				<Grid
					columnCount={columnCount || 0}
					noRulerOnBottom={noRulerOnBottom}
					noRulers={noRulers}
					noMinWidthOnCells={noMinWidthOnCells}>
					{rows.map((row, rowIndex, allRows) =>
						row.map((cell, cellIndex) => (
							<CellWrapper
								key={cellIndex}
								className={
									rowIndex === allRows.length - 1 ? "last" : undefined
								}>
								<div style={{ flexGrow: 1, display: "flex" }}>{cell}</div>
							</CellWrapper>
						)),
					)}
				</Grid>
			)}
			{!rows.length && noDataMessage && <Text>{noDataMessage}</Text>}
		</>
	) : null;
};

export default LineTable;
