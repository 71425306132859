import { SVGGradient } from "Assets/SVG/SVGGradient";

// Types
import type IconProps from "Types/IconProps";

const KeyIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "key-icon",
	fill,
	id,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "28px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 28 14"
			fill={fill === "gradient" ? "url(#svgGradient)" : fill || "currentColor"}
			xmlns="http://www.w3.org/2000/svg">
			<path d="M26.6206 2.82071C26.5225 2.68894 26.4283 2.55416 26.3324 2.4211C26.0784 2.16707 25.8244 1.91305 25.5703 1.65903C25.2087 1.41451 24.861 1.14623 24.4838 0.928926C22.0028 -0.503626 18.8919 -0.259107 16.6657 1.52899C15.9689 2.08888 15.3917 2.74986 14.9601 3.53309C14.8802 3.67825 14.7942 3.7275 14.6279 3.72707C10.8992 3.72188 7.17008 3.7275 3.44138 3.71584C3.08756 3.71454 2.83786 3.81736 2.59377 4.07095C1.84164 4.85246 1.06531 5.61064 0.299356 6.37876C-0.0998228 6.7788 -0.100255 7.20001 0.298924 7.60092C1.10506 8.41051 1.91379 9.21708 2.7169 10.0293C2.8478 10.1615 2.9895 10.225 3.17483 10.2228C3.46212 10.2193 3.74984 10.2219 4.03669 10.2375C4.20561 10.247 4.32182 10.1861 4.4255 10.0591C4.78883 9.6141 5.15906 9.17517 5.52454 8.7315C5.77209 8.43125 5.86022 8.4252 6.10819 8.71637C6.45726 9.12635 6.79941 9.54238 7.14977 9.9515C7.32906 10.1606 7.45996 10.1481 7.63708 9.93206C7.97837 9.5156 8.32053 9.09957 8.66786 8.68786C8.87696 8.43989 8.97546 8.43902 9.18153 8.68138C9.53534 9.09698 9.88268 9.51776 10.233 9.93638C10.421 10.1606 10.5389 10.1584 10.7312 9.92817C11.0763 9.51473 11.4193 9.09957 11.7688 8.68959C11.9844 8.43643 12.0777 8.43729 12.2968 8.6965C12.6355 9.09655 12.9824 9.49097 13.2969 9.90959C13.4792 10.152 13.6749 10.2712 13.9838 10.2422C14.2542 10.2168 14.5717 10.1541 14.7899 10.2638C14.9938 10.3667 15.0927 10.676 15.2405 10.8924C17.3327 13.955 21.4083 14.8773 24.5841 12.97C28.2687 10.7568 29.0126 6.03272 26.621 2.82114L26.6206 2.82071ZM14.2944 6.01587L4.68385 6.01112C4.44667 6.01112 4.25443 5.81844 4.25443 5.58126C4.25443 5.34409 4.44667 5.15184 4.68428 5.15184L14.2948 5.1566C14.532 5.1566 14.7242 5.34927 14.7242 5.58645C14.7242 5.70482 14.6763 5.81239 14.5985 5.89015C14.5208 5.96791 14.4132 6.01587 14.2948 6.01587H14.2944ZM22.4996 8.2943C21.7695 7.56766 21.766 6.38265 22.4914 5.66119C23.2124 4.94361 24.3897 4.93973 25.112 5.65255C25.846 6.37703 25.8537 7.5534 25.1288 8.28436C24.4 9.01965 23.2323 9.02397 22.4992 8.2943H22.4996Z" />
			{fill === "gradient" && <SVGGradient />}
		</svg>
	);
};

export default KeyIcon;
