// Styles
import { Text } from "./styles";

// Types
import { PropsWithChildren } from "react";

export const GradientText: React.FC<PropsWithChildren> = ({
	children,
	...props
}) => {
	return <Text {...props}>{children}</Text>;
};

export default GradientText;
