import React from "react";

// Local
import DefaultDashboardPartnerPage from "./Components/DefaultDashboardPartnerPage";

const DashboardPartnerPage = () => {
	return <DefaultDashboardPartnerPage />;
};

export default DashboardPartnerPage;
