import styled from "@emotion/styled";

export const Warning = styled.p(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.D3,
	textAlign: "center",
}));

export const Chart = styled.div({ aspectRatio: "1" });

export const UpgradeMessageHeading = styled.h2(({ theme }) => ({
	textAlign: "center",
	...theme.webFonts.h2,
	color: theme.colors.primary,
}));

export const UpgradeMessage = styled.p(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.D2,
	textAlign: "center",
}));

export const FeaturesList = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	placeItems: "center",
	margin: "3rem 0",
	"& > ul, li": {
		textAlign: "left",
		...theme.webFonts.bold,
		color: theme.colors.primary,
		listStyle: "none",
		padding: "0.8rem",
		margin: "auto 0",
	},
	"& svg": {
		margin: "0 0.6rem -0.8rem 0",
	},
}));

export const Link = styled.a(({ theme }) => ({
	...theme.webFonts.bold,
	color: theme.colors.D2,
	textDecoration: "underline",
	"& :hover": {
		color: theme.colors.primary,
	},
	transition: "color 0.2s",
}));
