import React from "react";

// CC
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import AppError from "CCW-Components/CCW-AppError";
import Container from "CCW-Components/CCW-Container";
import Sharehub from "CCW-Components/CCW-Sharehub";

// Hooks
import useSession from "Hooks/UseSession";

// Helpers
import replacePartnerImage from "Helpers/NetworkingHelpers/ReplacePartnerImage";

// Local Helpers
import { getRemainingSubs, getUsedSubs } from "./Helpers";

// Types
import type { PartnerAccount } from "Types/Account";
import useCountPartnerConnectedAccounts from "Hooks/UseCountPartnerConnectedAccounts";

const DefaultDashboardPartnerPage: React.FC = () => {
	// Hooks
	const [, , , { activePartner: partner }] = useSession<PartnerAccount>();
	const [counts, countError] = useCountPartnerConnectedAccounts(partner?._id);

	// Error state
	if (countError) {
		return (
			<DefaultPage>
				<AppError error={countError as Error} />
			</DefaultPage>
		);
	}

	// Loading state
	if (!partner || !counts)
		return <BlockLoader message="Loading Partner data..." />;

	return (
		<DefaultPage>
			<Container>
				<Sharehub
					onBannerChange={([image]) =>
						replacePartnerImage(partner._id, image, "banner")
					}
					onLogoChange={([image]) =>
						replacePartnerImage(partner._id, image, "logo")
					}
					counts={{
						signups: {
							home: counts.homes,
							business: counts.businesses,
							school: counts.schools,
						},
						subsidiesUsed: getUsedSubs(partner),
						subsidiesLeft: getRemainingSubs(partner),
					}}
					name={partner.name}
					images={{
						bannerSRC: partner.banner,
						logoSRC: partner.logo,
					}}
					referralCode={partner.referralCode}
					platformsToIncludeInLinks={["home", "business", "school"]}
					websitesToIncludeInLinks={["landing", "sign up"]}
				/>
			</Container>
		</DefaultPage>
	);
};

export default DefaultDashboardPartnerPage;
