import * as ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

// CC
import MetaHeader from "./CCW-Components/CCW-MetaHeader";
import AppDownMessage from "CCW-Components/CCW-AppDownMessage";

// App
import PartnerApp from "./Apps/Partner";

// Context
import AlertContextProvider from "./Contexts/AlertContextProvider";
import IntercomContextProvider from "Contexts/IntercomContextProvider";

// Helpers
import makeBrowserTracing from "./Helpers/TrackingHelpers/BrowserTracing";

// Styles
import { Layout } from "./styles";

// Assets
import favicon from "./Assets/Images/favicon.ico";

// Main CSS
import "./CSS/main.css";
import Theme from "Theme";

if (import.meta.env.VITE_SENTRY_FLAG === "on") {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY,
		integrations: [makeBrowserTracing()],
		tracesSampleRate: 0.2,
		beforeSend(event) {
			// Check if it is an exception and showUserFeedback, and if so, show the report dialog
			if (event.exception && event.tags?.showUserFeedback === "yes") {
				Sentry.showReportDialog({
					eventId: event.event_id,
					user: event.user,
				});
			}
			return event;
		},
	});
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

if (import.meta.env.VITE_MAINTENANCE === "on") {
	root.render(
		<Theme>
			<AppDownMessage />
		</Theme>,
	);
} else {
	root.render(
		<Theme>
			<MetaHeader
				title="ClimateClever"
				favIconURL={favicon}
				manifestURL="/manifest.json">
				<AlertContextProvider>
					<IntercomContextProvider>
						<Layout>
							<PartnerApp />
						</Layout>
					</IntercomContextProvider>
				</AlertContextProvider>
			</MetaHeader>
		</Theme>,
	);
}
