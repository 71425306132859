import useSWR from "swr";
import { KeyedMutator } from "swr";

// Helpers
import getReq from "Helpers/NetworkingHelpers/GetReq";

// Types
import { Counts } from "Types/Sharehub";

type Response = {
	v: string;
	jsonapi: {
		version: string;
	};
	meta: Counts;
};

/**
 * Get the number of accounts connected to a Partner of ID
 * @param partnerID Partner's ID
 * @returns a tuple of Count, Error, Mutate and FullResponse.
 */
const useCountPartnerConnectedAccounts = (
	partnerID?: string,
): [
	data: Counts | undefined,
	error: Error | undefined,
	mutate: KeyedMutator<Response> | undefined,
	fullResponse: Response | undefined,
] => {
	const { data, error, mutate } = useSWR<Response, Error>(
		partnerID
			? [
					`${
						import.meta.env.VITE_BASE_URL
					}executive/partners/${partnerID}/account-count`,
					1,
			  ]
			: null,
		getReq,
		{
			shouldRetryOnError: false,
		},
	);
	return [data?.meta, error, mutate, data];
};

export default useCountPartnerConnectedAccounts;
