import React, { Suspense, useEffect } from "react";
import { Route, BrowserRouter } from "react-router-dom";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import PrivateRoute from "CCW-Components/CCW-PrivateRoute";
import FullPageAlert from "CCW-Components/CCW-FullPageAlert";
import TransitionRoutes from "CCW-Components/CCW-TransitionRoutes";
import Comms from "CCW-Components/CCW-Comms";
import NavBar from "CCW-Components/CCW-NavBar";

// Hooks
import useSession from "Hooks/UseSession";

// Pages
import DashboardPartnerPage from "Pages/DashboardPartner";
import LoginPage from "Pages/Login";
import SetFirstPassword from "Pages/SetFirstPassword";
import ForgotPasswordPage from "Pages/ForgotPassword";
import ResetPasswordPage from "Pages/ResetPassword";
import Missing404Page from "Pages/Missing404";
import ConfirmEmailChange from "Pages/ConfirmEmailChange";
import SignUpPage from "Pages/SignUp";
import WelcomePage from "Pages/Welcome";
import PartnerTrackPage from "Pages/Track/partner-track";
import APIKeysPage from "Pages/APIKeys";

// Helpers
import { initTracking } from "Helpers/TrackingHelpers/Events";
import lazyWithRetries from "Helpers/LazyWithRetries";

// Assets
import {
	ReportIcon,
	AnalyticsIcon,
	SettingsIcon,
	KeyIcon,
	BureaucraticIcon,
} from "Assets/SVG";

// Types
import type { PartnerAccount } from "Types/Account";

// Lazy loaded
const PartnerReportsPage = lazyWithRetries(
	() => import("Pages/PartnerReports"),
);
const SettingsPage = lazyWithRetries(() => import("Pages/Settings"));
const FeatureRequestPage = lazyWithRetries(
	() => import("Pages/FeatureRequest"),
);
const ImpactReport = lazyWithRetries(() => import("Pages/ImpactReport"));

function PartnerApp() {
	// Hooks
	const [
		activeUser,
		sessionError,
		,
		{ activePartner: partner, activeAccount },
	] = useSession<PartnerAccount>();

	// Helpers
	useEffect(() => {
		initTracking();
	}, []);

	// Loading State
	if (!activeUser && !sessionError) {
		return (
			<div
				style={{
					width: "100%",
					height: "100vh",
				}}>
				<BlockLoader message="Loading page........." />
			</div>
		);
	}

	return (
		<BrowserRouter>
			{!sessionError && activeUser && partner ? (
				<NavBar
					navItems={[
						{
							to: "/impact-report",
							title: "Impact Report",
							icon: ReportIcon,
						},
						{ to: "/track", title: "Track", icon: AnalyticsIcon },
					]}
					menuItems={[
						...((partner && activeAccount?.permission === "Account Admin") ||
						activeAccount?.permission === "Manager"
							? [{ to: "/api-keys", title: "API Keys", icon: KeyIcon }]
							: []),
						{
							to: "/feature-requests",
							title: "Request a Feature",
							icon: BureaucraticIcon,
						},
						{ to: "/settings", title: "Settings", icon: SettingsIcon },
					]}
					accountName={partner.name}
				/>
			) : null}
			<main>
				<FullPageAlert />
				<Comms user={activeUser} account={activeAccount} />
				<Suspense
					fallback={
						<div
							style={{
								width: "100%",
								height: "100vh",
							}}>
							<BlockLoader message="Loading page....." />
						</div>
					}>
					<TransitionRoutes isWithSentry={true}>
						<Route
							path="/sign-up/*"
							element={<SignUpPage wizardType="partner" />}
						/>
						<Route
							path="/*"
							element={
								<PrivateRoute user={activeUser}>
									<DashboardPartnerPage />
								</PrivateRoute>
							}
						/>
						<Route
							path="/api-keys"
							element={
								<PrivateRoute user={activeUser}>
									<APIKeysPage />
								</PrivateRoute>
							}
						/>
						<Route
							path="/reports/*"
							element={
								<PrivateRoute user={activeUser}>
									<PartnerReportsPage />
								</PrivateRoute>
							}
						/>
						<Route
							path="/settings/*"
							element={
								<PrivateRoute user={activeUser}>
									<SettingsPage />
								</PrivateRoute>
							}
						/>
						{["/track", "/track/:chartId"].map((route) => (
							<Route
								key={route}
								path={route}
								element={
									<PrivateRoute user={activeUser}>
										<PartnerTrackPage />
									</PrivateRoute>
								}
							/>
						))}
						<Route
							path="/set-reset-password"
							element={<ForgotPasswordPage />}
						/>
						<Route path="/password-reset" element={<ResetPasswordPage />} />
						<Route path="/health" element={<div>Ok</div>} />
						<Route path="/login" element={<LoginPage appType="partner" />} />
						<Route path="/set-first-password" element={<SetFirstPassword />} />
						<Route
							path="/verify-update"
							element={
								<PrivateRoute user={activeUser}>
									<ConfirmEmailChange />
								</PrivateRoute>
							}
						/>
						<Route
							path="/feature-requests/*"
							element={
								<PrivateRoute user={activeUser}>
									<FeatureRequestPage />
								</PrivateRoute>
							}
						/>
						<Route
							path="/impact-report/*"
							element={
								<PrivateRoute user={activeUser}>
									<ImpactReport isPage />
								</PrivateRoute>
							}
						/>
						<Route path="/welcome" element={<WelcomePage />} />
						<Route element={<Missing404Page />} />
					</TransitionRoutes>
				</Suspense>
			</main>
		</BrowserRouter>
	);
}

export default PartnerApp;
