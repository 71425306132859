import React, { useEffect, useMemo } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { useParams } from "react-router-dom";

// CC
import BlockLoader from "CCW-Components/CCW-BlockLoader";
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import Container from "CCW-Components/CCW-Container";
import AppError from "CCW-Components/CCW-AppError";

// Assets
import TickIcon from "Assets/SVG/CompletedIcon";

// Hooks
import useAPICharts from "Hooks/UseAPICharts";
import useSession from "Hooks/UseSession";
import useAlert from "Hooks/UseAlert";

// Locals
import ChartSelector from "../ChartSelector";

// Styles
import {
	Warning,
	Chart,
	UpgradeMessageHeading,
	UpgradeMessage,
	FeaturesList,
	Link,
} from "./styles";
import { Banner } from "Pages/PartnerReports/Components/DefaultReportsPage/styles";
import colors from "Theme/Styles/Colors";

// Types
import type { PartnerAccount } from "Types/Account";

const DBChartsPage: React.FC = () => {
	// Hooks
	const [apiCharts, error, , , { meta } = { meta: undefined }] = useAPICharts();
	const [, reportAlert] = useAlert();
	const [, partnerError, , { activeAccount: partner }] =
		useSession<PartnerAccount>();
	const { chartId } = useParams();
	const chartsSDK = useMemo(
		() =>
			new ChartsEmbedSDK({
				baseUrl: meta?.baseURL,
			}),
		[meta?.baseURL],
	);

	// Helpers
	useEffect(() => {
		if (!chartId || !apiCharts || !meta) return;
		const chart = chartsSDK.createChart({
			chartId: chartId,
			getUserToken: () => meta.token,
			autoRefresh: false,
			showAttribution: false,
		});
		const container = document.getElementById("injected-chart");
		if (!container) return;
		chart
			.render(container)
			.catch(() => reportAlert(`Failed to load chart: ${chartId}`, "error"));
	}, [apiCharts, chartId, chartsSDK, meta, reportAlert]);

	// Errors
	if (partnerError) {
		return <AppError error={partnerError} />;
	}

	if (error) {
		return (
			<Warning>
				{`Error loading the charts, please contact support and quote this message: \n ${error.message}`}
			</Warning>
		);
	}

	// Loading State
	if (!apiCharts || (!partnerError && !partner)) {
		return <BlockLoader />;
	}

	if (partner?.tier !== "standard" && partner?.tier !== "pro") {
		const tick = <TickIcon width="3rem" height="3rem" fill={colors.Green} />;
		const emailContent = {
			address: "support@climateclever.org",
			subject: `Upgrade%20partner%20account%20(Partner ID: ${partner?._id})`,
			body: "Hi,%20we%20would%20like%20to%20update%20our%20ClimateClever%20partner%20account.",
		};
		const emailLink = `mailto:${emailContent.address}?subject=${emailContent.subject}&body=${emailContent.body}`;

		return (
			<DefaultPage>
				<Container>
					<UpgradeMessageHeading>
						Upgrade to unlock these features
						<UpgradeMessage>
							Your charts are waiting for you!
							<br />
							Get detailed breakdowns on your all your:
							<FeaturesList>
								<ul>
									<li>{tick} emissions</li>
									<li>{tick} actions</li>
									<li>{tick} users</li>
									<li>{tick} and more...</li>
								</ul>
							</FeaturesList>
						</UpgradeMessage>
						<UpgradeMessage>
							Upgrade your account today to access these features!
							<br />
							Chat to us on Intercom or email us at{" "}
							<Link href={emailLink}>support@climateclever.org</Link>
						</UpgradeMessage>
					</UpgradeMessageHeading>
				</Container>
			</DefaultPage>
		);
	}

	return (
		<DefaultPage>
			<Container>
				<Banner>
					We are still working on these charts so you may find issues
				</Banner>
				<ChartSelector charts={apiCharts} />
				<Chart id="injected-chart"></Chart>
			</Container>
		</DefaultPage>
	);
};

export default DBChartsPage;
