import React from "react";

// Hooks
import useBreakpoints from "Hooks/UseBreakpoints";

// Helpers
import selectPlatformIcon from "Helpers/IconHelpers/PlatformIcons";
import { breakpoints } from "Helpers/WebUIHelpers/Breakpoints";

// Styles
import {
	OuterContainer,
	Heading,
	PlatformTitle,
	Divider,
	TierName,
	Amount,
	HorizontalStack,
	VerticalStack,
	ContentContainer,
} from "./styles";

// Types
import type { SharehubInfoContainerProps } from "./types";
import type Platform from "Types/Platform";

const SharehubInfoContainer: React.FC<SharehubInfoContainerProps> = ({
	title,
	signUpsPerPlatform,
	icon: Icon,
	...props
}) => {
	const sharehubCounts = Object.values(signUpsPerPlatform);
	const accountTypes = Object.keys(signUpsPerPlatform);
	const signupBreakdown = sharehubCounts.filter(
		(count) => typeof count === "number",
	);
	const subsidyBreakdown = sharehubCounts.filter(
		(count) => typeof count !== "number",
	);

	// Hooks
	const clientWidth = useBreakpoints();

	// Helpers
	const isMobile = clientWidth < breakpoints[2];

	return (
		<OuterContainer id="partner-header-dashboard" {...props}>
			<Heading>
				<span>{title}</span>
				<Icon />
			</Heading>
			{signupBreakdown && typeof signupBreakdown[0] === "number" && (
				<ContentContainer>
					<HorizontalStack allowVerticalForMobile={isMobile}>
						{signupBreakdown.map((signupCount, index) => {
							const accountType = accountTypes[index];
							const AccountTypeIcon = selectPlatformIcon(
								accountType as Platform,
							);
							return (
								<VerticalStack key={accountType}>
									<AccountTypeIcon width="3rem" height="3rem" fill="gradient" />
									<PlatformTitle>{accountType}</PlatformTitle>
									<Amount>{signupCount as number}</Amount>
								</VerticalStack>
							);
						})}
					</HorizontalStack>
				</ContentContainer>
			)}
			{subsidyBreakdown && typeof subsidyBreakdown === "object" && (
				<ContentContainer>
					{subsidyBreakdown.map((tiers, index) => {
						const accountType = accountTypes[index];
						const AccountTypeIcon = selectPlatformIcon(accountType as Platform);

						return tiers ? (
							<>
								<HorizontalStack allowVerticalForMobile>
									<HorizontalStack
										allowVerticalForMobile
										style={{ flexGrow: 1, justifyContent: "start" }}>
										<AccountTypeIcon
											width="3rem"
											height="3rem"
											fill="gradient"
										/>
										<PlatformTitle>{accountType}</PlatformTitle>
									</HorizontalStack>
									<HorizontalStack allowVerticalForMobile={isMobile}>
										{Object.entries(tiers).map(([name, amount]) => (
											<VerticalStack key={name}>
												<TierName>{name}</TierName>
												<Amount>{amount}</Amount>
											</VerticalStack>
										))}
									</HorizontalStack>
								</HorizontalStack>
								<Divider />
							</>
						) : null;
					})}
				</ContentContainer>
			)}
		</OuterContainer>
	);
};

export default SharehubInfoContainer;
