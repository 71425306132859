import React, { useState } from "react";
import { Navigate } from "react-router-dom";

// CC
import DefaultPage from "CCW-Components/CCW-DefaultPage";
import FormCard from "CCW-Components/CCW-FormCard";

// Local CC
import SetFirstPasswordForm from "../SetFirstPasswordForm";

// Styles
import { SetContainer, LoginCard } from "./styles";
import ButtonLink from "CCW-Components/CCW-ButtonLink";

const DefaultSetFirstPassword: React.FC = () => {
	// Hooks
	const searchParams: URLSearchParams = new URLSearchParams(location.search);
	const token = searchParams.get("token");
	const [success, setSuccess] = useState(false);

	// No token
	if (!token) {
		return <Navigate to="/" />;
	}

	return (
		<DefaultPage defaultPadding={false} onNavigation={true}>
			<SetContainer>
				<FormCard
					title={success ? "Account ready" : "Account Set Up"}
					heading={success ? "" : "Set your password"}
					text={[
						success ? (
							<>
								<span className="end">Congratulations!</span>
								<span className="start">
									Thanks for joining us on our climate action journey!
								</span>
							</>
						) : (
							<>
								<span key="set-up-1">{"You're "}</span>
								<span key="set-up-2" className="start">
									one step closer{" "}
								</span>
								<span key="set-up-3">to doing your part to help </span>
								<span key="set-up-4" className="end">
									save the environment
								</span>
							</>
						),
					]}>
					{success ? (
						<LoginCard>
							<ButtonLink type="button" block to="/login">
								Login now
							</ButtonLink>
						</LoginCard>
					) : (
						<SetFirstPasswordForm {...{ token }} onSuccess={setSuccess} />
					)}
				</FormCard>
			</SetContainer>
		</DefaultPage>
	);
};

export default DefaultSetFirstPassword;
