import styled from "@emotion/styled";

// Styles
export const Title = styled.span(({ theme }) => ({
	...theme.webFonts.F7,
	...theme.webFonts.bold,
	display: "block",
	color: theme.colors.D2,
	marginBottom: "0.4rem",
	marginLeft: "0.8rem",
}));

export const Text = styled.span(({ theme }) => ({
	...theme.webFonts.F7,
	marginLeft: "0.8rem",
}));

export const CellWrapper = styled.div({
	height: "100%",
	display: "flex",
	alignItems: "center",
});

export const Grid = styled.div<{
	columnCount: number;
	noRulerOnBottom?: boolean;
	noRulers?: boolean;
	noMinWidthOnCells?: boolean;
}>(({ theme, columnCount, noRulerOnBottom, noRulers, noMinWidthOnCells }) => {
	const firstElementOfRowSelector = `& > *:nth-of-type(${columnCount}n + 1)::after`;
	const rowHeightInPixels = 36;
	return {
		...theme.webFonts.F7,
		color: theme.colors.D2,
		padding: "0 0.8rem",
		alignItems: "center",
		lineHeight: 1,
		display: "grid",
		position: "relative",
		gridTemplateColumns: `1fr${" auto".repeat(columnCount - 1)}`,
		gridAutoRows: `${rowHeightInPixels}px`,
		columnGap: "8%",
		"& > *": {
			minWidth: noMinWidthOnCells ? "unset" : "6rem", // This is used to pad out the cells. Set noMinWidthOnCells if you want everything packed tightly.
		},
		...(!noRulers
			? {
					[firstElementOfRowSelector]: {
						// Hacky row ruler
						content: "''",
						position: "absolute",
						left: "0",
						right: "0",
						marginTop: `${rowHeightInPixels}px`,
						height: "1px",
						background: theme.colors.grey200,
					},
					...(noRulerOnBottom
						? {
								"& .last::after": {
									height: 0,
								},
						  }
						: {}),
			  }
			: {}),
	};
});
