import React from "react";

// Types
import type IconProps from "Types/IconProps";

export const LinkIcon: React.FC<IconProps> = ({
	width,
	height,
	className = "link-icon",
	fill,
	id,
	...props
}) => {
	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "29px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 26 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15.3284 9.88294C15.1384 9.73916 14.9586 9.73916 14.7747 9.89731C14.0779 10.5091 13.4741 11.2035 13.0446 12.0539C12.8255 12.4852 12.8411 12.537 13.221 12.8109C14.3622 13.6404 14.9172 14.7712 14.7815 16.2542C14.7069 17.0392 14.3568 17.6977 13.8615 18.2483C12.2922 19.9951 10.731 21.7535 9.10056 23.4356C8.11946 24.447 6.88394 24.6304 5.60634 24.1077C3.41144 23.2092 2.77637 20.2259 4.37625 18.3827C4.99978 17.6639 5.65248 16.9709 6.30383 16.2794C6.49652 16.0745 6.53587 15.8804 6.4592 15.6051C6.09935 14.2928 5.98911 12.9178 6.13489 11.56C6.15592 11.348 6.18034 11.1366 6.2102 10.8584C6.11724 10.9483 6.07925 10.9814 6.04465 11.0187C4.69401 12.478 3.34336 13.9383 1.99272 15.3995C1.18057 16.2721 0.593078 17.3507 0.286322 18.5323C0.16148 19.0139 0.0936313 19.5113 0 20.0016V21.2956C0.0312104 21.4789 0.0644581 21.6622 0.0929545 21.8455C0.962776 27.4929 6.93618 29.8047 11.0539 26.7208C11.6008 26.311 12.075 25.7863 12.5466 25.2745C13.8608 23.8648 15.1683 22.4472 16.4547 21.0095C16.8227 20.6007 17.145 20.1484 17.4154 19.6616C18.2425 18.1563 18.4745 16.5187 18.2235 14.8093C17.9182 12.7368 16.9194 11.11 15.3284 9.88294Z"
				fill={fill || "currentColor"}
			/>
			<path
				d="M25.8932 6.88458C25.4325 3.96529 23.8441 1.94314 21.1967 1.01222C18.5492 0.0812926 16.1311 0.668601 14.1072 2.70226C12.6396 4.1752 11.2725 5.75957 9.84559 7.2778C8.243 8.98222 7.50413 11.0223 7.71785 13.4176C7.9214 15.6899 8.89299 17.5187 10.6577 18.8572C10.8504 19.001 11.0038 18.9967 11.172 18.8198C11.8227 18.1362 12.4313 17.4166 12.9246 16.5949C13.1356 16.2434 13.1118 16.1672 12.778 15.9314C11.7481 15.2061 11.1978 14.1954 11.168 12.8741C11.1456 11.8548 11.5662 11.0331 12.206 10.3279C13.7306 8.64795 15.2538 6.9651 16.8103 5.31819C17.6706 4.40883 18.7494 4.12776 19.9259 4.4383C22.441 5.10253 23.3332 8.37622 21.5543 10.3761C20.9273 11.0806 20.2855 11.7707 19.6423 12.4608C19.4727 12.6427 19.4306 12.8202 19.5066 13.0653C19.7735 13.9272 19.9048 14.8296 19.8953 15.7366C19.8953 16.4231 19.8275 17.1089 19.7868 17.8213C19.837 17.7753 19.8892 17.7336 19.934 17.6847C21.291 16.2183 22.6609 14.7654 23.9988 13.2803C25.6164 11.4838 26.2786 9.32871 25.8932 6.88458Z"
				fill={fill || "currentColor"}
			/>
		</svg>
	);
};

export default LinkIcon;
