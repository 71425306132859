import styled from "@emotion/styled";

// Helpers
import MediaQueries from "Helpers/WebUIHelpers/Breakpoints";

export const Banner = styled.div(({ theme }) => ({
	...theme.webFonts.F5,
	color: theme.colors.D3,
	border: `0.2rem solid ${theme.colors.Grape300}`,
	borderRadius: "0.8rem",
	marginTop: "1.5rem",
	backgroundColor: theme.colors.White,
	padding: "2.4rem 1.8rem",
	[MediaQueries[1]]: {
		padding: "2.4rem 3.6rem",
	},
}));

export const Header = styled.div(({ theme }) => ({
	display: "flex",
	gap: "2rem",
	alignItems: "center",
	color: theme.colors.Grape300,
	...theme.webFonts.F3Bold,
	marginBottom: "3rem",
}));
