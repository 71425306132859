import React from "react";

// Types
import type IconProps from "Types/IconProps";

const CircleArrowIcon: React.FC<IconProps & { direction?: "down" | "up" }> = ({
	width,
	height,
	id,
	className = "circle-arrow-icon",
	direction = "down",
	fill,
	...props
}) => {
	function arrowD() {
		if (direction === "down") {
			return "M18 0C27.9411 0 36 8.05884 36 18C36 27.9412 27.9411 36 18 36C8.05888 36 0 27.9412 0 18C0 8.05884 8.05888 0 18 0ZM26.5606 20.5605L19.0606 28.0605C18.4749 28.6465 17.5251 28.6465 16.9394 28.0605L9.43936 20.5605C8.85356 19.975 8.85356 19.025 9.43936 18.4395C10.0251 17.8535 10.9749 17.8535 11.5606 18.4395L16.5 23.3785V9C16.5 8.17163 17.1716 7.5 18 7.5C18.8284 7.5 19.5 8.17163 19.5 9V23.3785L24.4394 18.4395C25.0251 17.8535 25.9749 17.8535 26.5606 18.4395C27.1464 19.025 27.1464 19.975 26.5606 20.5605Z";
		}
		if (direction === "up") {
			return "M18 36C27.9411 36 36 27.9412 36 18C36 8.05884 27.9411 0 18 0C8.05888 0 0 8.05884 0 18C0 27.9412 8.05888 36 18 36ZM26.5606 15.4395L19.0606 7.93945C18.4749 7.35352 17.5251 7.35352 16.9394 7.93945L9.43936 15.4395C8.85356 16.025 8.85356 16.975 9.43936 17.5605C10.0251 18.1465 10.9749 18.1465 11.5606 17.5605L16.5 12.6215V27C16.5 27.8284 17.1716 28.5 18 28.5C18.8284 28.5 19.5 27.8284 19.5 27V12.6215L24.4394 17.5605C25.0251 18.1465 25.9749 18.1465 26.5606 17.5605C27.1464 16.975 27.1464 16.025 26.5606 15.4395Z";
		}
		return "M1 1L6 6L11 1";
	}

	return (
		<svg
			{...(width || (!height && !width) ? { width: width ?? "36px" } : {})}
			{...{ className, id, height, ...props }}
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d={arrowD()}
				fill={fill != null ? fill : "#828282"}
			/>
		</svg>
	);
};

export default CircleArrowIcon;
